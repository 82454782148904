import React from 'react';

import { rhythm } from '../utils/typography';
import BaseLayout from './base';
import styled from 'styled-components';

const ErrorContainer = styled.div`
  margin: 0 auto;
  marginbottom: ${rhythm(1.5)};
  margintop: ${rhythm(1.5)};
  maxwidth: 650;
  paddingleft: ${rhythm(3 / 4)};
  paddingright: ${rhythm(3 / 4)};
`;

const ErrorLayout: React.FC = ({ children }) => (
  <BaseLayout title="Error">
    <ErrorContainer>{children}</ErrorContainer>
  </BaseLayout>
);

export default ErrorLayout;
