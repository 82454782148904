import { PageProps } from 'gatsby';
import * as React from 'react';

import ErrorLayout from '../layouts/error';

const Error404Page: React.FC<PageProps> = () => (
  <ErrorLayout>
    <h1>You are here!</h1>
    <h2>But nothing found for you #404</h2>
  </ErrorLayout>
);

export default Error404Page;
